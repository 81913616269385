module.exports.copyConstants = {
  // Text only, no Markdown or HTML
  legalFooterCopy:
    'Starling Bank is registered in England and Wales as Starling Bank Limited (No. 09092149), 5th Floor, London Fruit And Wool Exchange, 1 Duval Square, London, E1 6PW. We are authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority under registration number 730166.',
  copyright: `© Starling Bank ${new Date().getUTCFullYear()}. All rights reserved.`,
  SMEAccountsTotal: '500,000',
  TotalCustomerAccounts: '3.1 million',
  BBABestBankYear: 'Britain’s Best Bank 2021',
  BBABestBankYearsRunning: 'four years in a row',
  BBACurrentAccountYear: 'Best Current Account 2022',
  BBABusinessAccountYear: 'Best Business Banking Provider 2021',
  JoinStarlingEveryXSeconds: '38',
  sendMoneyCountryNumber: '34',
  sendMoneyCurrencyNumber: '17',
  treesPlanted: '100,000',
  FTDRate: '4.05%'
};
